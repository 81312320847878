<template>
  <div style="max-width: 600px; margin: 1rem auto;">
    <v-card elevation="0" :loading="loading" style="border: 1px solid rgba(0, 0, 0, 0.12);">
      <v-card-title>
        {{ name }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="padding: 0;">
        <v-container>
          <v-row v-if="default_length > 0">
            <v-col cols="12" sm="12" md="6">
              <fieldset class="talentics-fieldset talentics-fieldset-paddings">
                <legend>Length</legend>
                <div style="color: #757575; margin-top: -2px;">
                  <v-icon left color="#757575">access_time</v-icon>{{ default_length }} min
                </div>
              </fieldset>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                v-model="timeZone"
                :label="`Timezone`"
                hide-details
                :items="timeZones"
                :item-text="item => `${item.text} - ${item.value}`"
                prepend-inner-icon="public"
                outlined
                dense
                @change="handleTimeZoneChange"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div>
              <v-container>
                <v-row>
                  <v-col cols="12" md="auto">
                    <div class="text-center">
                      <v-date-picker
                        v-model="picker"
                        no-title
                        show-adjacent-months
                        :first-day-of-week="1"
                        :allowed-dates="allowedDates"
                        @change="(newVal) => handleDatePickerChange({ newVal })"
                        :disabled="loading"
                      ></v-date-picker>
                    </div>
                  </v-col>
                  <v-col align-self="center" v-if="!picker">
                    <div class="text-center"><v-icon left>chevron-left</v-icon>Please select a date</div>
                  </v-col>
                  <v-col v-else>
                    <div style="margin-bottom: 1rem;">{{ format(new Date(picker), 'EEEE, LLLL d') }}</div>
                    <div v-for="(item, index) in items" :key="`item_${index}`" style="margin-bottom: .5rem;">
                      <v-btn block depressed outlined color="primary" :disabled="loading" @click="handleTimeSlotClick({ start: item.start.dateTime, end: item.end.dateTime, startZoned: item.startZoned.dateTime, endZoned: item.endZoned.dateTime })">
                        {{ item.startZoned.dateTime }}
                      </v-btn>
                    </div>
                    <div v-if="!loading && (items.length === 0)">
                      No available time slots.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-tab-item>
          <v-alert 
              v-if="getEventListError"
              type="error"
              tile
              style="margin: 0;"
          >
          An unexpected error has occurred. Please try again later.
          </v-alert>
          <v-tab-item>
            <div style="padding: .25rem;">
              <ValidationObserver
                ref="form_schedule"
                tag="form"
                @submit="handleSubmit"
                id="form_schedule"
              >
                <v-container>
                  <v-row>
                    <v-col>
                      <v-icon left small>event</v-icon>{{ startZoned }} - {{ endZoned }}, {{ format(new Date(picker), 'EEEE, LLLL d') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <v-text-field
                          v-model="form.name"
                          type="text"
                          outlined
                          dense
                          hide-details
                          label="Name *"
                          :error-messages="errors"
                          :disabled="loading || success"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <ValidationProvider rules="required|email" v-slot="{ errors }">
                        <v-text-field
                          v-model="form.email"
                          type="email"
                          outlined
                          dense
                          hide-details
                          label="Email *"
                          :error-messages="errors"
                          :disabled="loading || success"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </ValidationObserver>
            </div>
            <v-divider></v-divider>
            <v-alert
              v-if="err"
              type="error"
              tile
              style="margin: 0;"
            >
              An unexpected error has occurred. Please try again later.
            </v-alert>
            <v-sheet style="display: flex; padding: .5rem 1rem;" v-if="!success">
              <v-btn @click="handleBackClick" depressed :disabled="loading"><v-icon left>chevron_left</v-icon>Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="handleSubmit" :disabled="loading"><v-icon left>schedule_send</v-icon> Schedule Event</v-btn>
            </v-sheet>
            <v-alert
              v-if="success"
              type="success"
              tile
              style="margin: 0;"
            >
              A calendar invitation has been sent to your email address.
            </v-alert>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { startOfDay, format } from 'date-fns';
import { ValidationObserver, ValidationProvider } from "vee-validate";
import api from '@/api';

export default {
  name: 'InstantBooking',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      format,
      loading: false,
      picker: null,
      name: '',
      default_length: 0,
      timeZone: null,
      items: [],
      tab: 0,
      start: null,
      end: null,
      startZoned: null,
      endZoned: null,
      form: {
        name: '',
        email: '',
      },
      success: false,
      err: false,
      timeZones: [],
      getEventListError: false,
    };
  },
  methods: {
    getTimezones() {
      this.loading = true;
      api.timeZones.get().then((response) => {
        const { items } = response.data;
        this.timeZones = items.reduce((acc, item) => {
        return [
          ...acc,
          { header: item.group },
          ...item.zones.map(zone => ({
            text: zone.name,
            value: zone.value,
            group: item.group,
          })),
        ];
      }, []);
      });
    },
    getEvents({ id, date, timeZone }) {
      this.loading = true;
      api.events.get({ id, date, timeZone })
        .then((response) => {
          this.getEventListError = false;
          const { items } = response.data;
          this.items = items;
        }, () => {
          this.items = [];
          this.getEventListError = true;
        })
        .catch(() => {
          // foo.
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEventType({ id }) {
      this.loading = true;
      api.eventType.get({ id }).then((response) => {
        const {
          name,
          default_length,
          timezone,
        } = response.data;
        this.name = name;
        this.default_length = default_length;
        this.timeZone = timezone;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    },
    allowedDates(val) {
      const startOfRelatedDay = startOfDay(new Date(val));
      const startOfToday = startOfDay(new Date());
      
      if (startOfRelatedDay < startOfToday) {
        return false;
      }

      const dayOfWeekForRelatedDay = format(new Date(val), 'i');

      if (dayOfWeekForRelatedDay == '6' || dayOfWeekForRelatedDay == '7') {
        return false;
      }

      return true;
    },
    handleDatePickerChange({ newVal }) {
      this.getEvents({ id: this.$route.params.id, date: newVal, timeZone: this.timeZone });
    },
    handleTimeSlotClick({ start, end, startZoned, endZoned }) {
      this.start = start;
      this.end = end;
      this.startZoned = startZoned;
      this.endZoned = endZoned;
      this.tab = 1;
    },
    handleBackClick() {
      this.tab = 0;
    },
    async handleSubmit(evt) {
      if (evt) {
        evt.preventDefault();
      }
      const isValid = await this.$refs.form_schedule.validate();

      if (isValid) {
        const { start, picker, timeZone } = this;
        const { name, email } = this.form;
        const { id } = this.$route.params;
        this.loading = true;
        this.err = false;
        api.events.post({
          id,
          start,
          date: picker,
          name,
          email,
          timeZone,
        }).then(() => {
          this.loading = false;
          this.success = true;
        }, () => {
          this.err = true;
          this.loading = false;
        });
      }
    },
    handleTimeZoneChange() {
      const { picker } = this;
      if (picker) {
        this.getEvents({ id: this.$route.params.id, date: this.picker, timeZone: this.timeZone });
      }
    }
  },
  created() {
    this.getTimezones();
  },
  watch: {
    '$route.params.id': {
      handler: function (newVal, oldVal) {
        if (newVal && (newVal !== oldVal)) {
          this.getEventType({ id: newVal });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
fieldset.talentics-fieldset {
  border: 1px solid #9E9E9E;
  border-radius: 4px;
  margin-top: -10px;

  legend {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }

  &.talentics-fieldset-paddings {
    padding: 0px .5rem 6px .5rem;

    legend {
      margin-left: 2px;
    }
  }

  & > * {
    border-radius: 4px;
  }
}
</style>