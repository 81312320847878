import axios from 'axios';

export default {
  eventType: {
    get({ id, ...params }) {
      return axios.get(`/v2/instant-booking-app/${id}`, { params });
    },
  },
  timeZones: {
    get() {
      return axios.get(`/v2/instant-booking-app/timezones`);
    },
  },
  events: {
    get({ id, ...params }) {
      return axios.get(`/v2/instant-booking-app/${id}/events`, { params });
    },
    post({ id, ...params }) {
      return axios.post(`/v2/instant-booking-app/${id}/events`, params);
    },
  },
};
