<template>
  <v-app>
    <v-main class="ib-main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  created() {
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  },
};
</script>

<style lang="scss">
.ib-main {
  background-color: rgba(242,245,245,0.8);
}
</style>